import Swiper from "swiper";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
// import Swiper and modules styles
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./../public/fonts/druk/stylesheet.css";
import "./styles/global.scss";
import "./styles/home.scss";
import "./styles/bot.scss";
import "./styles/about.scss";
import "./styles/products.scss";
const tabsHeaders = document.querySelectorAll(".tabs-header");
const expander = document.querySelectorAll(".expander");
const expanderIconsPlus = document.querySelectorAll(".plus");

const videoContainer = document.querySelector(".video");
const video = document.getElementById("main-video");
const headerButton = document.getElementById("header-button");
// video.src = "/static/matryoshka_tg_bot.mp4";
// video.load();

// video.addEventListener(
//   "loadeddata",
//   function () {
//     videoContainer.style.opacity = 1;
//   },
//   false
// );
// tabsHeaders.forEach((th, i) =>
//   th.addEventListener("click", () => {
//     if (expander[i].classList.contains("expanded")) {
//       expander[i].classList.remove("expanded");
//       expanderIconsPlus[i].classList.remove("active");
//     } else {
//       expander[i].classList.add("expanded");
//       expanderIconsPlus[i].classList.add("active");
//     }
//   })
// );

// init Swiper:

const header = document.querySelector(".header");
let lastScrollPosition = 0;
console.log({ header });
window.addEventListener("scroll", () => {
  const currentScrollPosition =
    window.scrollY || document.documentElement.scrollTop;

  if (currentScrollPosition > lastScrollPosition) {
    header.style.transform = "translateY(-100%)";
  } else {
    header.style.transform = "translateY(0)";
  }

  lastScrollPosition = currentScrollPosition <= 0 ? 0 : currentScrollPosition;
});
// window.addEventListener("load", () => {
//   const canvas = document.getElementById("action-canvas");
//   canvas.width = 600;
//   canvas.height = 600;
//   var ctx = canvas.getContext("2d");
//   const img = new Image();
//   console.log("1");
//   img.onload = () => {
//     console.log("3");
//     ctx.drawImage(img, 0, 0, 450, 450);
//   };
//   img.src = "/static/telegram.png";
//   class Particle {
//     constructor() {
//       this.x = 0;
//       this.y = 0;
//       this.size = 10;
//     }
//     draw() {
//       ctx.fillRect(this.x, this.y, this.size, this.size);
//     }
//   }

//   function animate() {}
// });
const menuToggle = document.querySelector(".menu-toggle");
const siteNavigation = document.querySelector(".primary-navigation");
const siteNavigationLinks = document.querySelectorAll(
  ".primary-navigation ul li a"
);
menuToggle.addEventListener("click", () => {
  const isOpened = menuToggle.getAttribute("aria-expanded") === "true";
  isOpened ? closeMenu() : openMenu();
});

function openMenu() {
  menuToggle.setAttribute("aria-expanded", "true");
  siteNavigation.setAttribute("data-state", "opened");
}
function closeMenu() {
  console.log("d");
  menuToggle.setAttribute("aria-expanded", "false");
  siteNavigation.setAttribute("data-state", "closing");

  siteNavigation.addEventListener(
    "animationend",
    () => {
      siteNavigation.setAttribute("data-state", "closed");
    },
    { once: true }
  );
}
